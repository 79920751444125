import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PortfolioData } from "@/data";
import PortfolioCard from "@/components/portfolio-card";

const PortfolioOne = () => {
  // Initialize state to track the number of items to display
  const [visibleItems, setVisibleItems] = useState(6); // Display 6 items initially

  // Function to handle loading more items
  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6); // Load 6 more items each time
  };

  return (
    <section className="commonSection porfolioPage">
      <Container>
        <Row id="Grid">
          <div className="custom">
            <Row>
              {PortfolioData.slice(0, visibleItems).map((post, index) => (
                <Col lg={4} md={6} sm={12} key={index}>
                  <PortfolioCard data={post} />
                </Col>
              ))}
            </Row>
          </div>
        </Row>
        {visibleItems < PortfolioData.length && (
          <Row>
            <Col lg={12} className="text-center">
              <button className="common_btn red_bg" onClick={loadMore}>
                <span>Load More</span>
              </button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default PortfolioOne;
